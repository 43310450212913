<template>
  <section class="invoice-preview-wrapper">

    <b-row>
      <b-col sm="12" md="3" class="p-0 m-0">
        <navlink></navlink>
      </b-col>
      <b-col sm="12" md="9">
        <b-card>
          <b-card-body>
            <div class="d-flex justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: GENAPP_APP_STORE_MODULE_NAME + '-edit' }"
              >
                Edit
              </b-button>
            </div>
            <h3 class="mt-1">Personel Deatails</h3>
            <div>
              <b-row
                class="p-lg-4 p-md-2 justify-content-center align-items-center"
              >
                <div class="col-6 ">
                  <ul class="list-unstyled">
                    <li>
                      <h5>Name</h5>
                      <p>aqw</p>

                      <!-- <p> {{ data.item.name ? data.data.item.name : "" }}</p> -->
                    </li>
                    <li>
                      <h5>Email</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>Last Name</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>Educational Qualification</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>Date Of Birthr</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>Citizen</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>Currency</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>Residential Status</h5>
                      <p>aqw</p>
                    </li>
                  </ul>
                </div>
                <div class="col-6 p-0">
                  <ul class="list-unstyled p-0">
                    <li>
                      <h5>Gender</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>City</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>Language Preference</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>Marital Status</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>Place Of Birth</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>Residential Status</h5>
                      <p>aqw</p>
                    </li>
                    <li>
                      <h5>Refered By</h5>
                      <p>aqw</p>
                    </li>
                  </ul>
                </div>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import viewsidelist from "@/views/Component/viewsidelist.vue";
import {
  BAvatar,
  BRow,
  BCol,
  BAlert,
  BLink,
  BCardText,
  BCard,
  BTable,
  BTableLite,
  BCardBody,
  BForm,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import useList from "./useList";
import { useToast } from "vue-toastification/composition";
import Logo from "@core/layouts/components/Logo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCol,
    BCard,
    BAvatar,
    viewsidelist,
    BRow,
    BCardBody,
    BCardText,
    BForm,
    BTable,
    BCardBody,
    BTableLite,
    BButton,
    ToastificationContent,
    BAlert,
    BLink,
    Logo,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "settings-profile";
    const modulename = "Profile";
    const modulenamesub = "profile";
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    const toast = useToast();
    const appData = ref(null);
    const serviceprov = ref(null);

    const printview = () => {
      window.print();
    };
    const { deletefun } = useList();
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        if (!response.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          router
            .push({
              name: GENAPP_APP_STORE_MODULE_NAME,
            })
            .catch(() => { });

        }
        appData.value = response.data.data;


      })
      .catch((error) => {
        if (error.response.status === 404) {
          appData.value = undefined;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 500) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Input fields missing values",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 422) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Please refresh your page",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
        router
          .push({
            name: GENAPP_APP_STORE_MODULE_NAME,
          })
          .catch(() => { });
      });


    return {
      GENAPP_APP_STORE_MODULE_NAME,
      deletefun,
      modulename,
      modulenamesub,
      appData,
      printview,
      serviceprov,
    };
  },
  methods: {
    checkdeletefun(val) {
      var self = this;
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deletefun(val);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: this.modulename + " has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          self.$router.push({
            name: this.GENAPP_APP_STORE_MODULE_NAME,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  a {
    color: black;
    text-decoration: none !important;
  }

  .d-print-block {
    display: block !important;
  }

  .content-header-right {
    display: none;
  }

  .invoice-actions {
    display: none;
  }

  .breadcrumbs-top {
    display: none;
  }

  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-wipricing: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-wipricing: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
}</style>
